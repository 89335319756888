<template>
  <div class="custom-container">
    <el-row>
      <div class="flex justify_start">
        <el-page-header
          :title="lang != 'en' ? '返回' : 'Back'"
          @back="goBack()"
          :content="lang != 'en' ? '客户编辑' : 'Customer Edit'"
        ></el-page-header>
      </div>
    </el-row>

    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <p class="dark mt10 mb30">
          <!-- 基础资料 -->
          {{ lang != 'en' ? '基础资料' : 'Base Info' }}
        </p>

        <div class="pro-container">
          <el-row>
            <!-- 公司名称 -->
            <el-col :span="6" style="display: flex">
              <span style="word-break: keep-all">
                {{ $t('cusDetail.CompanyName') }}：
              </span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="form.companyVO.companyName"
                placement="top-start"
                :disabled="!form.companyVO.companyName"
              >
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ form.companyVO.companyName || '--' }}
                </span>
              </el-tooltip>
            </el-col>
            <!-- 网址 -->
            <el-col :span="6" style="display: flex">
              <span style="word-break: keep-all">
                {{ $t('cusDetail.WebAddress') }}：
              </span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="form.companyVO.website"
                placement="top-start"
                :disabled="!form.companyVO.website"
              >
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ form.companyVO.website || '--' }}
                </span>
              </el-tooltip>
            </el-col>
            <!-- 公司地址 -->
            <el-col :span="6" style="display: flex">
              <span style="word-break: keep-all">
                {{ $t('cusDetail.CompanyAddress') }}：
              </span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="computedCompanyDetailedAddress"
                placement="top-start"
                :disabled="!computedCompanyDetailedAddress"
              >
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ computedCompanyDetailedAddress || '--' }}
                </span>
              </el-tooltip>
            </el-col>
            <!-- 公司级别 -->
            <el-col :span="6" style="display: flex">
              {{ $t('cusManage.CustomerLevel') }}：
              <el-rate
                v-model="form.companyVO.companyLevel"
                disabled
                text-color="#ff9900"
              ></el-rate>
            </el-col>
          </el-row>

          <el-row class="mt20">
            <!-- 邮编 -->
            <el-col :span="6" style="display: flex">
              <span style="word-break: keep-all">
                {{ $t('cusDetail.ZipCode') }}：
              </span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="form.companyVO.postcode"
                placement="top-start"
                :disabled="!form.companyVO.postcode"
              >
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ form.companyVO.postcode || '--' }}
                </span>
              </el-tooltip>
            </el-col>
            <!-- 传真 -->
            <el-col :span="6" style="display: flex">
              <span style="word-break: keep-all">
                {{ $t('cusDetail.FaxNumber') }}：
              </span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="form.companyVO.faxNumber"
                placement="top-start"
                :disabled="!form.companyVO.faxNumber"
              >
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ form.companyVO.faxNumber || '--' }}
                </span>
              </el-tooltip>
            </el-col>
            <!-- 备注 -->
            <el-col :span="6" style="display: flex">
              <span style="word-break: keep-all">
                {{ $t('cusDetail.Remark') }}：
              </span>
              <el-tooltip
                class="item"
                effect="dark"
                :content="form.companyVO.remark"
                placement="top-start"
                :disabled="!form.companyVO.remark"
              >
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ form.companyVO.remark || '--' }}
                </span>
              </el-tooltip>
            </el-col>
          </el-row>
        </div>

        <!-- tabs -->
        <el-tabs v-model="active" class="mt30">
          <!-- 客户信息 -->
          <el-tab-pane
            :label="$t('inquiryDetails.CustomerInformation')"
            name="1"
          >
            <el-table
              :data="[form.infoShowVO]"
              class="input-table"
              :max-height="520"
              :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }"
              border
            >
              <!-- 客户编码 -->
              <el-table-column
                :label="$t('cusManage.CustomerCode')"
                prop="customerCode"
                width="160"
              ></el-table-column>
              <!-- 客户名称 -->
              <el-table-column
                :label="$t('cusManage.CustomerName')"
                prop="customerName"
                label-class-name="required-th"
              >
                <el-form-item
                  prop="infoShowVO.customerName"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: $t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <el-input
                    v-model="form.infoShowVO.customerName"
                    :placeholder="$t('placeholder.plsInput')"
                    maxlength="100"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-table-column>
              <!-- 联系电话 -->
              <el-table-column
                :label="$t('cusManage.ContactNumber')"
                label-class-name="required-th"
              >
                <el-form-item
                  prop="infoShowVO.phone"
                  :rules="[
                    {
                      required: true,
                      trigger: 'blur',
                      message: $t('placeholder.plsInput'),
                    },
                  ]"
                >
                  <el-input
                    v-model="form.infoShowVO.phone"
                    :placeholder="$t('placeholder.plsInput')"
                    maxlength="200"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-table-column>
              <!-- 客户邮箱 -->
              <el-table-column
                :label="$t('cusManage.CustomerMailbox')"
                width="300"
                label-class-name="required-th"
              >
                <template slot-scope="scope">
                  <div
                    class="email-wrap"
                    v-for="(item, i) in scope.row.linkMainEmails"
                    :key="i"
                  >
                    <el-form-item
                      :prop="'infoShowVO.linkMainEmails.' + i + '.email'"
                      :rules="[
                        {
                          required: true,
                          trigger: 'blur',
                          message: $t('placeholder.plsInput'),
                        },
                        {
                          type: 'email',
                          message: $t('placeholder.formatError'),
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model="form.infoShowVO.linkMainEmails[i].email"
                        @change="
                          handleRepeatMailsAfterMailDataChange($event, i)
                        "
                        @blur="emailBlur(i)"
                        :placeholder="$t('placeholder.plsInput')"
                        maxlength="300"
                        clearable
                      ></el-input>
                    </el-form-item>

                    <i
                      v-if="i === 0"
                      class="el-icon-circle-plus-outline"
                      @click="emailAdd"
                    ></i>
                    <i
                      v-else
                      class="el-icon-remove-outline red"
                      @click="emailDel(i)"
                    ></i>
                  </div>
                  <p class="email-err" v-show="!isSameMailSuffix">
                    {{
                      lang === 'en'
                        ? 'Tip: The email addresses entered are not the same suffix, please check for errors'
                        : '提示：输入的邮箱并非同一个后缀，请核对是否有误'
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="Skype">
                <el-form-item prop="skype">
                  <el-input
                    v-model="form.infoShowVO.skype"
                    :placeholder="$t('placeholder.plsInput')"
                    maxlength="200"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-table-column>
              <el-table-column label="WhatsApp">
                <el-form-item prop="whatsapp">
                  <el-input
                    v-model="form.infoShowVO.whatsapp"
                    :placeholder="$t('placeholder.plsInput')"
                    maxlength="200"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-table-column>
              <el-table-column label="WeChat">
                <el-form-item prop="wechat">
                  <el-input
                    v-model="form.infoShowVO.wechat"
                    :placeholder="$t('placeholder.plsInput')"
                    maxlength="200"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-table-column>
              <!-- 客户来源 -->
              <el-table-column
                :label="$t('cusManage.CustomerSource')"
                prop="customerSourceId"
              >
                <el-form-item>
                  <el-select
                    v-model="form.infoShowVO.customerSourceId"
                    :placeholder="$t('placeholder.plsSel')"
                  >
                    <el-option
                      v-for="(item, i) in customerList"
                      :key="i"
                      :label="item.sourceName"
                      :value="item.sourceId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-table-column>
            </el-table>
            <!-- 已存在的邮箱展示 -->
            <div class="mt10">
              <div v-for="(item, index) in repeatMails" :key="index">
                <p v-if="msgType == 1">
                  {{ item.email
                  }}{{
                    lang === 'en'
                      ? 'The current account already exists'
                      : '当前账号已存在'
                  }}, {{ lang === 'en' ? 'principal' : '负责人' }}:{{
                    lang === 'en' ? item.belongerNameEn : item.belongerName
                  }}
                </p>
                <p v-else-if="msgType == 2">
                  {{ item.email
                  }}{{
                    lang === 'en'
                      ? 'The current account already exists'
                      : '当前账号已存在'
                  }}, {{ lang === 'en' ? 'principal' : '负责人' }}:{{
                    lang === 'en' ? item.belongerNameEn : item.belongerName
                  }}{{
                  lang === 'en'
                  ? 'Adding is no longer supported. If you need to obtain management rights, please go to Inquiry
                  Management and add an inquiry form. After adding an inquiry form, the system will assign management
                  rights;'
                  : '不再支持新增了，如果需要获得分管权，请去询盘管理中，增加询盘单，添加询盘单后，系统会分配分管权限；'
                  }}
                </p>
                <p v-else>
                  {{ item.email
                  }}{{
                    lang === 'en'
                      ? 'The current account does not have permission, please contact the person in charge of the account:'
                      : '当前账号无权限，请联系客户负责人:'
                  }}{{ lang === 'en' ? item.belongerNameEn : item.belongerName
                  }}{{
                    lang === 'en'
                      ? 'Configure assigned operations'
                      : '配置分管操作'
                  }}
                </p>
              </div>
            </div>
          </el-tab-pane>
          <!-- 付款方式 -->
          <el-tab-pane name="2" :label="$t('cusManage.PaymentMethod')">
            <el-table
              :data="form.companyVO.paymentEditVOs"
              class="input-table"
              :max-height="520"
              :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }"
              border
            >
              <!-- label="序号" -->
              <el-table-column
                :label="$t('cusDetail.SerialNumber')"
                type="index"
                width="50"
              ></el-table-column>
              <!-- label="付款方式" -->
              <el-table-column
                :label="$t('cusManage.PaymentMethod')"
                label-class-name="required-th"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.payway">
                    {{ scope.row.payway }}
                  </div>
                  <el-form-item
                    v-else
                    :prop="
                      'companyVO.paymentEditVOs.' + scope.$index + '.paymentId'
                    "
                    :rules="[
                      {
                        required: true,
                        trigger: 'change',
                        message: $t('placeholder.plsSel'),
                      },
                    ]"
                  >
                    <el-select
                      v-model="
                        form.companyVO.paymentEditVOs[scope.$index].paymentId
                      "
                      @change="paymentChange($event, scope.$index)"
                      :placeholder="$t('placeholder.plsSel')"
                      style="width: 100%"
                      clearable
                    >
                      <el-option
                        v-for="item in paymentList"
                        :key="item.paymentId"
                        :label="item.payway"
                        :value="item.paymentId"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <!-- label="金额区间" -->
              <el-table-column
                :label="$t('cusDetail.AmountRange')"
                label-class-name="required-th"
                width="300"
              >
                <template slot-scope="scope">
                  <el-row v-if="scope.row.payType === 1 && !scope.row.isAdd">
                    {{ lang === 'en' ? 'All' : '全部' }}
                  </el-row>
                  <el-row v-else-if="scope.row.isAdd" class="pl5 pr5">
                    <el-form-item
                      :prop="
                        'companyVO.paymentEditVOs.' + scope.$index + '.payType'
                      "
                      :rules="[
                        {
                          required: scope.row.payway,
                          trigger: 'change',
                          message: $t('placeholder.plsSel'),
                        },
                      ]"
                    >
                      <el-radio-group
                        v-model="
                          form.companyVO.paymentEditVOs[scope.$index].payType
                        "
                        :disabled="!scope.row.payway"
                        @change="payTypeChange($event, scope.$index, scope.row)"
                      >
                        <el-radio :label="1">{{lang === 'en' ? 'All' : '全部'}}</el-radio>
                        <el-radio :label="2">{{lang === 'en' ? 'Custom' : '自定义'}}</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <el-row
                      v-if="
                        form.companyVO.paymentEditVOs[scope.$index].payType ===
                        2
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <el-col :span="11">
                        <el-form-item
                          :ref="`beginSection${scope.$index}`"
                          :prop="
                            'companyVO.paymentEditVOs.' +
                            scope.$index +
                            '.beginSection'
                          "
                          :rules="[
                            {
                              required: true,
                              trigger: 'change',
                              message: '请输入自定义区间',
                            },
                          ]"
                        >
                          <el-input
                            v-model.number="
                              form.companyVO.paymentEditVOs[scope.$index]
                                .beginSection
                            "
                            :placeholder="$t('placeholder.plsInput')"
                            type="number"
                            clearable
                            oninput="value=value.replace(/[^0-9]/g,'')"
                            @change="
                              beginSectionChange(
                                $event,
                                scope.$index,
                                scope.row
                              )
                            "
                          />
                        </el-form-item>
                      </el-col>
                      <el-col
                        :span="2"
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        -
                      </el-col>
                      <el-col :span="11">
                        <el-form-item
                          :prop="
                            'companyVO.paymentEditVOs.' +
                            scope.$index +
                            '.endSection'
                          "
                        >
                          <el-input
                            v-model.number="
                              form.companyVO.paymentEditVOs[scope.$index]
                                .endSection
                            "
                            :placeholder="$t('placeholder.plsInput')"
                            type="number"
                            clearable
                            oninput="value=value.replace(/[^0-9]/g,'')"
                            @change="
                              endSectionChange($event, scope.$index, scope.row)
                            "
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-row>
                  <el-row v-else class="pl5 pr5">
                    <span
                      v-if="
                        (scope.row.beginSection === 0 ||
                          scope.row.beginSection) &&
                        scope.row.endSection
                      "
                    >
                      {{ scope.row.beginSection }}-{{ scope.row.endSection }}
                    </span>
                    <span v-else>
                      <span v-if="scope.row.beginSection">
                        &gt;{{ scope.row.beginSection }}
                      </span>
                      <!-- <span>--</span> -->
                    </span>
                  </el-row>
                </template>
              </el-table-column>
              <!-- label="状态" -->
              <el-table-column width="120" :label="$t('orderDetails.Status')">
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.payway.includes('100%deposit') &&
                      scope.row.payStatus == 2
                    "
                  >
                    {{ lang != 'en' ? '无需审核' : 'No Review Required' }}
                  </span>
                  <span v-else>
                    {{ scope.row.payStatus | payStatusTrans(lang) }}
                  </span>
                </template>
              </el-table-column>
              <!-- label="操作" -->
              <el-table-column :label="$t('cusDetail.Operate')" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    class="ml10"
                    @click="payDel(scope.$index)"
                    v-show="scope.row.payStatus != 1"
                    style="color: red"
                  >
                    <!-- 删除 -->
                    {{ $t('orderDetails.Delete') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <p class="add-line">
              <span @click="add(1)">
                <!-- 新增一行 -->
                {{ $t('cusDetail.Add') }}
              </span>
            </p>
          </el-tab-pane>
          <!-- 收货地址 -->
          <el-tab-pane :label="$t('orderDetails.ShippingAddress')" name="3">
            <el-table
              :data="form.companyVO.addressList"
              class="input-table"
              :max-height="520"
              :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }"
              border
            >
              <el-table-column
                :label="$t('cusDetail.SerialNumber')"
                width="50"
                type="index"
                fixed="left"
              ></el-table-column>
              <!-- label="详细收货地址" -->
              <el-table-column
                :label="$t('cusCenter.Detailedshippingaddress')"
                width="300"
              >
                <template slot-scope="scope">
                  {{ getReceivingAddress(scope.row, scope.$index) }}
                </template>
              </el-table-column>
              <!-- 收货公司 -->
              <el-table-column
                label-class-name="required-th"
                :label="$t('cusDetail.ReceivingCompany')"
                prop="companyName"
                width="200px"
              ></el-table-column>
              <!-- 收货人 -->
              <el-table-column
                label-class-name="required-th"
                :label="$t('cusDetail.Receiver')"
                prop="consigneeName"
                width="160px"
              ></el-table-column>
              <!-- 联系电话 -->
              <el-table-column
                label-class-name="required-th"
                width="160px"
                :label="$t('cusDetail.ContactNumber')"
                prop="consigneePhone"
              ></el-table-column>
              <!-- label="国家" -->
              <el-table-column
                label-class-name="required-th"
                width="120px"
                :label="$t('cusDetail.Country')"
                prop="nation"
              ></el-table-column>
              <!-- 邮编 -->
              <el-table-column
                label-class-name="required-th"
                :label="$t('cusDetail.ZipCode')"
                width="160px"
                prop="postcode"
              ></el-table-column>
              <!-- 州/省 -->
              <el-table-column
                label-class-name="required-th"
                :label="$t('cusDetail.StateProvince')"
                prop="province"
                width="160px"
              ></el-table-column>
              <!-- 城市 -->
              <el-table-column
                label-class-name="required-th"
                width="120px"
                :label="$t('cusDetail.City')"
                prop="city"
              ></el-table-column>
              <!-- 街道地址 -->
              <el-table-column
                label-class-name="required-th"
                :label="$t('cusDetail.StreetAddress')"
                prop="street"
                width="200px"
              ></el-table-column>
              <!-- 单元号地址 -->
              <el-table-column
                :label="$t('cusDetail.UnitNoAddress')"
                prop="unit"
                width="160px"
              >
                <template slot-scope="scope">
                  {{ scope.row.unit || '--' }}
                </template>
              </el-table-column>
              <!-- 状态 -->
              <el-table-column width="120" :label="$t('orderDetails.Status')">
                <template slot-scope="scope">
                  {{ scope.row.status | payStatusTrans(lang) }}
                </template>
              </el-table-column>
              <!-- 默认地址 -->
              <el-table-column
                width="120"
                :label="$t('orderDetails.DefaultAddress')"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    @click="SetDefaultAddress(scope.$index)"
                    :class="scope.row.defaultAddressFlag === 1 ? 'icon-b' : ''"
                    size="mini"
                    icon="el-icon-star-off"
                    circle
                    plain
                  ></el-button>
                </template>
              </el-table-column>
              <!-- :label="操作 -->
              <el-table-column
                align="center"
                :label="$t('cusDetail.Operate')"
                :width="120"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    circle
                    v-show="scope.row.status != 1"
                    @click="addReceiveGoodsAddress(scope.row, scope.$index)"
                  >
                    {{ $t('orderDetails.Edit') }}
                  </el-button>
                  <el-button
                    circle
                    type="text"
                    @click="delAddress(scope.$index)"
                    v-show="scope.row.status != 1"
                    style="color: red"
                  >
                    <!-- 删除 -->
                    {{ $t('orderDetails.Delete') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>

            <p class="add-line">
              <span @click="addReceiveGoodsAddress('')">
                <!-- 新增一行 -->
                {{ $t('cusManage.Add') }}
              </span>
            </p>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>

    <div class="oper-btn">
      <el-button @click="goBack()">
        {{ lang === 'en' ? 'Cancel' : '取消' }}
      </el-button>
      <el-button @click="save" :loading="loading" type="primary">
        {{ lang === 'en' ? 'Confirm' : '确认' }}
      </el-button>
    </div>

    <!-- 审核模板-->
    <AuditChoose
      ref="AuditChoose"
      @auditData="saveClick"
      :title="lang === 'en' ? ' Customer Information Review' : '客户信息审核'"
    />
    <!-- 审核模板-->
    <AuditChoose
      ref="AuditChoose2"
      @auditData="saveClick2"
      :title="
        lang === 'en'
          ? 'Customer Installment Payment Plan Review'
          : '客户账期审核'
      "
    />
    <!-- 地址 -->
    <AddressAddModel
      ref="addressAddModel"
      @addressConfigSave="addAddress"
    ></AddressAddModel>
  </div>
</template>

<script>
  import { clientEditInstance } from '@/core/interactors/client-supplier/client-edit'
  import CustomerConfigInteractor from '@/core/interactors/client-supplier/client-setting'
  import { paymentListPage } from '@/api/client-supplier/supplier-setting'
  import { isEmail } from '@/utils/validate'
  import { mapGetters } from 'vuex'
  import AuditChoose from '@/components/AuditChoose'
  import AddressAddModel from './components/address-add-model'
  import { OrderListInteractor } from '@/core'
  import { FreightSettingInteractor } from '@/core/interactors/product/freightSetting'

  export default {
    name: 'ErpWebNewCustomerEdit',
    data() {
      return {
        loading: false,
        active: '1',
        infoId: '', // 用户id
        form: {
          companyVO: {},
          infoShowVO: {},
          auditObjectDTO: {}, // 审核模板
        },
        customerList: [], // 客户来源
        paymentList: [], // 付款方式
        defaultPayConfig: [], // 付款方式初始值
        defaultBaseInfo: {}, // 客户信息初始值
        repeatMails: [], // 重复的邮箱数据
        rules: {},
        isCheck: false, // 判断是否需要审核
        isDelAddress: false, // 判读收货地址有没有删除
        isSameMailSuffix: true,
        msgType: 1, //提示信息类型  1 可以申请分管 2 去询盘 3 无操作权限
        updateFlag: [],
        computedCompanyDetailedAddress: '',
        countryOptions: [],
      }
    },
    components: {
      AuditChoose,
      AddressAddModel,
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        lang: 'settings/language',
      }),
    },
    filters: {
      payStatusTrans(n, lang) {
        if (n) {
          switch (n) {
            case 1:
              return lang != 'en' ? '审核中' : 'under review'
            case 2:
              return lang != 'en' ? '审核通过' : 'examination passed'
            case 3:
              return lang != 'en' ? '审核驳回' : 'Review rejected'
          }
        } else {
          return '--'
        }
      },
    },
    created() {
      this.infoId = this.$route.query.infoId
      this.getMsgType()
      this.getIsCheck()
      this.getCustomerList()
      this.getPaymentList()
      this.getDetails(this.infoId)
    },
    methods: {
      // 获取国家列表
      async countryList() {
        let data = { level: 0 }
        let res = await OrderListInteractor.listCountryApi(data)
        if (res.code === '000000') this.countryOptions = res.data
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          return res.data
        } else {
          return []
        }
      },
      // 获取名称
      getDetailedAddress() {
        console.log(this.countryOptions)
        let countryObj = this.countryOptions?.filter(
          (item) => item.code === this.form.companyVO.country
        )?.[0]
        if (!countryObj) {
          this.computedCompanyDetailedAddress =
            this.form.companyVO.companyAddress
          return
        }
        this.getStateFn(countryObj.id).then((data) => {
          let ProvinceObj = data.filter(
            (item) => item.code === this.form.companyVO.province
          )?.[0]
          let companyCountry = countryObj.name
          let companyProvince = ProvinceObj?.name
          let addressArr = [
            companyCountry,
            companyProvince || this.form.companyVO.province,
            this.form.companyVO.city,
            this.form.companyVO.companyAddress,
          ]
          this.computedCompanyDetailedAddress = addressArr
            .filter((item) => item)
            .join('/')
        })
      },
      //获取消息类型
      async getMsgType() {
        const res = await CustomerConfigInteractor.getCustomerCommonConfigure(3)
        if (res?.code === '000000') {
          if (res.find((item) => item.operationType == '1').needTick !== 0) {
            this.msgType = 1
          } else if (
            res.find((item) => item.operationType == '2').needTick !== 0
          ) {
            this.msgType = 2
          } else {
            this.msgType = 3
          }
        }
      },

      // 获取客户来源
      getCustomerList() {
        this.pubApi.customerSourceList({}).then((response) => {
          if (response.code === '000000') {
            this.customerList = response.data
          }
        })
      },

      // 获取付款方式
      async getPaymentList() {
        let response = await paymentListPage({ payee: 1 })
        if (response.code === '000000') {
          this.paymentList = response.data.map((item) => {
            return {
              ...item,
              payway: this.paywayArea(item) + '/' + item.payway,
            }
          })
        }
      },

      // 获取付款方式金额区间
      paywayArea(item) {
        if (item.payType === 1) {
          // return this.lang === 'en' ? 'All' : '全部'
          return 'All'
        } else {
          if (
            (item.beginSection === 0 || item.beginSection) &&
            item.endSection
          ) {
            return item.beginSection + '-' + item.endSection
          } else {
            return '>' + item.beginSection
          }
        }
      },

      // 判断是否需要审核
      async getIsCheck() {
        const _arr = await CustomerConfigInteractor.getCustomerCommonConfigure(
          1
        )

        this.isCheck =
          _arr.find((item) => item.operationType == '4')?.needTick === 1
            ? true
            : false
      },

      // 初始化编辑数据
      async getDetails(id) {
        await this.countryList()
        const _res = await clientEditInstance.getEditClientDetail(id)

        if (_res && _res.code === '000000') {
          this.form.companyVO = _res.data.companyVO
          this.form.companyVO.addressList =
            _res.data.companyVO.receivingAddressConfigVOs.map((item) => {
              return {
                ...item,
                defaultAddressFlag: item.defaultAddressFlag || 0,
              }
            })
          this.form.infoShowVO = _res.data.infoShowVO
          this.defaultPayConfig = JSON.parse(
            JSON.stringify(_res.data.companyVO.paymentEditVOs)
          )
          this.defaultBaseInfo = JSON.parse(
            JSON.stringify(_res.data.infoShowVO)
          )
          this.getDetailedAddress()
        }
      },

      // 邮箱添加
      emailAdd() {
        if (this.form.infoShowVO.linkMainEmails.length >= 10) {
          this.$message.warning(
            this.lang != 'en' ? '邮箱最多添加十条!' : 'Add up to 10 emails!'
          )
        } else {
          this.form.infoShowVO.linkMainEmails.push({
            email: '',
            linkMainId: '',
          })
        }
      },

      // 添加邮箱
      async emailBlur(i) {
        let Emails = this.form.infoShowVO.linkMainEmails

        if (Emails[i].email) {
          const mailData = Emails.slice()
          mailData.splice(i, 1)
          if (mailData.some((item) => item.email === Emails[i].email)) {
            Emails[i].email = ''
            return this.$message.warning(
              this.lang === 'en'
                ? 'The entered email address is duplicated'
                : '输入的邮箱重复'
            )
          }

          const _res = await clientEditInstance.checkEmail(Emails[i].email)

          if (_res?.code === '000000') {
            if (Emails[i].email) {
              if (_res.data?.repet === 1) {
                this.handleRepeatEmail({ ..._res.data, email: Emails[i].email })
                this.handleRepeatMailsAfterMailDataChange()
              }
            }
            this.getIsSameMailSuffix()
          }
        }
      },

      // 处理重复的邮箱
      handleRepeatEmail(repeatMail) {
        let emails = this.repeatMails.map((i) => i.email)
        if (!emails.includes(repeatMail.email)) {
          this.repeatMails.push(repeatMail)
        }
      },

      // 处理repeatMails
      handleRepeatMailsAfterMailDataChange(val, i) {
        if (i >= 0) {
          !val ? (this.form.infoShowVO.linkMainEmails[i].linkMainId = '') : ''
        }
        const mailData = this.form.infoShowVO.linkMainEmails
          .slice()
          .map((i) => i.email)
        this.repeatMails = this.repeatMails.filter((item) =>
          mailData.includes(item.email)
        )
      },

      // 邮箱删除
      emailDel(i) {
        this.form.infoShowVO.linkMainEmails.splice(i, 1)
        this.getIsSameMailSuffix()
        this.handleRepeatMailsAfterMailDataChange()
      },

      // 获取邮箱后缀
      getIsSameMailSuffix() {
        let mailsSuffixs = this.form.infoShowVO.linkMainEmails
          .filter((item) => isEmail(item.email))
          .map((item) => {
            let index = item.email.lastIndexOf('@')
            let suffix = item.email.slice(index)
            return suffix
          })

        this.isSameMailSuffix = new Set(mailsSuffixs).size < 2
      },

      // 付款方式删除
      payDel(i) {
        if (this.form.companyVO.paymentEditVOs?.length > 1) {
          const msg =
            this.lang === 'en' ? 'Are you sure to delete?' : '是否确认删除?'
          this.$confirm(msg, this.lang === 'en' ? 'Info' : '提示', {
            confirmButtonText: this.lang === 'en' ? 'Confirm' : '确认',
            cancelButtonText: this.lang === 'en' ? 'Cancel' : '取消',
            type: 'warning',
          }).then(() => {
            this.form.companyVO.paymentEditVOs.splice(i, 1)
          })
        } else {
          this.$message.warning(
            this.lang != 'en' ? '至少保留一条！' : 'Keep at least one!'
          )
        }
      },

      // 新增一行
      add(type) {
        if (type == 1) {
          this.form.companyVO.paymentEditVOs.push({
            isAdd: true,
            payType: '',
            beginSection: '',
            endSection: '',
            payway: '',
            paymentId: '',
          })
        } else {
          this.form.companyVO.addressList.push({
            address: '',
            addressConfigId: '',
            city: '',
            companyId: '',
            companyName: '',
            consigneeName: '',
            consigneePhone: '',
            defaultAdress: 0,
            infoId: '',
            nation: '',
            postcode: '',
            province: '',
            street: '',
            unit: '',
            defaultAddressFlag: 0, // 默认地址标记 0否 1是
          })
        }
      },

      addReceiveGoodsAddress(row, index) {
        if (!row) {
          return this.$refs.addressAddModel.showAddEdit()
        }
        this.$refs.addressAddModel.showAddEdit({ ...row }, index)
      },
      // 新增地址回调
      addAddress(data, index) {
        const addressList = this.form.companyVO.addressList
        if (index > -1) {
          //编辑
          addressList.splice(index, 1, data)
        } else {
          addressList.push(data)
        }
      },

      // 选择付款方式
      paymentChange(val, i) {
        if (val) {
          const _obj = this.paymentList.find((item) => item.paymentId === val)
          Object.assign(this.form.companyVO.paymentEditVOs[i], _obj)
          this.$forceUpdate()
        } else {
          this.$set(this.form.companyVO.paymentEditVOs, i, {
            payway: '',
            paymentId: '',
          })
        }
      },
      payTypeChange(val, i, row) {
        if (val === 1) {
          this.form.companyVO.paymentEditVOs[i].beginSection = ''
          this.form.companyVO.paymentEditVOs[i].endSection = ''
          let paywayArr = row.payway.split('/')
          paywayArr[0] = 'ALL'
          row.payway = paywayArr.join('/')
        }else {
          this.form.companyVO.paymentEditVOs[i].beginSection = ''
          this.form.companyVO.paymentEditVOs[i].endSection = ''
          this.$nextTick(()=>{
            this.$refs[`beginSection${i}`].clearValidate()
          })
        }
      },

      beginSectionChange(val, i, row) {
        if (val && row.endSection!=='' && Number(val) >= Number(row.endSection)) {
          this.$baseMessage(
            '前区间值需小于后区间值',
            'error',
            false,
            'erp-hey-message-error'
          )
          row.endSection = ''
        }
        let paywayArr = row.payway.split('/')
        if (row.endSection) {
          paywayArr[0] = `${val}-${row.endSection}`
        } else {
          paywayArr[0] = `>${val}`
        }
        row.payway = paywayArr.join('/')
      },

      endSectionChange(val, i, row) {
        if (val && Number(val) <= Number(row.beginSection)) {
          this.$baseMessage(
            '后区间值需大于前区间值',
            'error',
            false,
            'erp-hey-message-error'
          )
          row.endSection = ''
        } else {
          let paywayArr = row.payway.split('/')
          if(val) {
            paywayArr[0] = `${Number(row.beginSection)}-${val}`
          }else {
            paywayArr[0] = `>${row.beginSection}`
          }
          row.payway = paywayArr.join('/')
        }

      },

      // 删除地址
      delAddress(i) {
        const _address = this.form.companyVO.addressList

        if (_address?.length > 1) {
          const msg =
            this.lang === 'en' ? 'Are you sure to delete?' : '是否确认删除?'
          this.$confirm(msg, this.lang === 'en' ? 'Info' : '提示', {
            confirmButtonText: this.lang === 'en' ? 'Confirm' : '确认',
            cancelButtonText: this.lang === 'en' ? 'Cancel' : '取消',
            type: 'warning',
          }).then(() => {
            if (_address[i].addressConfigId) {
              this.isDelAddress = true
            }
            this.form.companyVO.addressList.splice(i, 1)
          })
        } else {
          this.$message.warning(
            this.lang != 'en' ? '至少保留一条！' : 'Keep at least one!'
          )
        }
      },

      // 传入校验未通过的字段，如果校验未通过，判断当前tab是否有校验未通过的字段，如果有则不跳转；
      // 如果当前tab校验通过，其他tab有校验未通过，从第一个tab开始，如果有校验未通过就跳转至此tab
      setActiveByNotPass(notPass) {
        const keys = Object.keys(notPass)
        const infoShowVOValid = keys.some((n) => n.includes('infoShowVO')) // 客户信息校验是否不通过
        const companyVOValid = keys.some((n) => n.includes('companyVO')) // 付款方式校验是否不通过
        const addressValid = keys.some((n) => n.includes('address')) // 收货地址校验是否不通过
        if (this.active == 1) {
          if (infoShowVOValid) {
            this.active = '1'
          } else if (companyVOValid) {
            this.active = '2'
          } else if (addressValid) {
            this.active = '3'
          }
        } else if (this.active == 2) {
          if (companyVOValid) {
            this.active = '2'
          } else if (infoShowVOValid) {
            this.active = '1'
          } else if (addressValid) {
            this.active = '3'
          }
        } else if (this.active == 3) {
          if (!this._checkAddress()) {
            this.active = '3'
          } else if (infoShowVOValid) {
            this.active = '1'
          } else if (companyVOValid) {
            this.active = '2'
          }
        }
      },

      hasDuplicates(array){
        return new Set(array).size !== array.length;
      },

      // 保存
      save() {
        // 收货地址校验
        // if (!this._checkAddress()) return

        this.$refs['ruleForm'].validate(async (valid, notPass) => {
          if (valid) {
            // 校验付款方式是否重复
            const checkPayMethod = this.hasDuplicates(this.form.companyVO.paymentEditVOs.map(item => item.payway))
            if(checkPayMethod) {
              return this.$baseMessage(
                this.lang != 'en'
                  ? '付款方式不能重复'
                  : 'Payment method cannot be repeated',
                'error',
                false,
                'erp-hey-message-error'
              )
            }

            // validate函数校验不到收货地址，所以收货地址的校验是在valid为真之后，再进行校验
            if (!this._checkAddress())
              return this.setActiveByNotPass({ address: [] })
            this.checkCondition()
            if (this.isCheck && this.updateFlag.length != 0) {
              // 1 修改了基本信息,删除地址
              // 2新增、删除支付方式
              let auditType = 17
              if (this.updateFlag.length === 1) {
                auditType = this.updateFlag[0] === 2 ? 22 : 17
              }
              let auditParams = {
                auditType,
                status: 0,
                tenantId: this.userInfo.tenantId,
              }
              this.pubApi.getAuditTemplates(auditParams).then((response) => {
                if (!response?.data?.length) {
                  this.$baseMessage(
                    this.lang != 'en'
                      ? '请联系管理员配置审核模板'
                      : 'Please contact your administrator to configure audit templates',
                    'warning',
                    false,
                    'erp-hey-message-warning'
                  )
                } else {
                  this.$refs[
                    auditType != 22 ? 'AuditChoose' : 'AuditChoose2'
                  ].showAddEdit(auditParams)
                }
              })
            } else {
              this.saveFinally()
            }
          } else {
            // 注意：notPass只校验到客户信息和付款方式，收货地址是通过_checkAddress函数进行校验的
            this.setActiveByNotPass(notPass)
          }
        })
      },

      // 地址校验
      _checkAddress() {
        const checkField = [
          'companyName',
          'consigneeName',
          'consigneePhone',
          'nation',
          'postcode',
          'province',
          'city',
          'street',
        ]
        const addressData = this.form.companyVO.addressList
        let checkFlag = true
        for (let i = 0; i < addressData.length; i++) {
          let item = addressData[i]
          if (
            item.companyName ||
            item.consigneeName ||
            item.consigneePhone ||
            item.nation ||
            item.postcode ||
            item.province ||
            item.nation ||
            item.street
          ) {
            for (let j = 0; j < checkField.length; j++) {
              if (!item[checkField[j]]) {
                this.$message.warning(
                  this.lang === 'en'
                    ? `Please input the ${i + 1} line whole address`
                    : `请输入第${i + 1}行完整收货地址`
                )
                checkFlag = false
                break
              }
            }
          }
          if (!checkFlag) {
            break
          }
        }

        return checkFlag
      },

      // 判断编辑是否走审核
      checkCondition() {
        this.updateFlag = []
        const [_info, _dInfo, _pays, _address] = [
          this.form.infoShowVO,
          this.defaultBaseInfo,
          this.form.companyVO.paymentEditVOs,
          this.form.companyVO.addressList,
        ]

        const [_emails, _dEmails] = [[], []]
        _info.linkMainEmails.forEach((item) => _emails.push(item.email))
        _dInfo.linkMainEmails.forEach((item) => _dEmails.push(item.email))

        // 判断基本信息是否修改
        if (
          _info.customerName != _dInfo.customerName ||
          _info.phone != _dInfo.phone ||
          _info.skype != _dInfo.skype ||
          _info.whatsapp != _dInfo.whatsapp ||
          _info.wechat != _dInfo.wechat ||
          _info.customerSourceId != _dInfo.customerSourceId ||
          _emails.toString() != _dEmails.toString()
        ) {
          // return false
          this.updateFlag.push(1)
        }
        // 判断收货地址有没有删除
        if (this.isDelAddress) {
          // return false
          if (!this.updateFlag.includes(1)) {
            this.updateFlag.push(1)
          }
        }
        // 判断有没有修改支付方式
        const newPay = _pays.map((item) => item.payway)
        const oldPay = this.defaultPayConfig.map((item) => item.payway)
        const addPay = []
        const deletePay = []
        for (let n of newPay) {
          if (!oldPay.includes(n)) {
            addPay.push(n)
          }
        }
        for (let d of oldPay) {
          if (!newPay.includes(d)) {
            deletePay.push(d)
          }
        }
        if (deletePay.length) {
          this.updateFlag.push(2)
          return
        }
        if (addPay.every((item) => item.includes('100%deposit'))) {
          return true
        } else {
          if (_pays.length != this.defaultPayConfig.length) {
            this.updateFlag.push(2)
            // return false
          } else {
            const [_ids, _dIds] = [[], []]
            _pays.forEach((item) => _ids.push(item.paymentId))
            this.defaultPayConfig.forEach((item) => _dIds.push(item.paymentId))

            if (_ids.sort().toString() === _dIds.sort().toString()) {
              return true
            } else {
              if (!this.updateFlag.includes(2)) {
                this.updateFlag.push(2)
              }
              return false
            }
          }
        }
      },

      // 保存审核模板信息
      async saveClick(data) {
        this.form.auditObjectDTO = data
        if (this.updateFlag.length > 1) {
          let auditParams = {
            auditType: 22,
            status: 0,
            tenantId: this.userInfo.tenantId,
          }

          this.pubApi.getAuditTemplates(auditParams).then((response) => {
            if (!response.data.length) {
              this.$baseMessage(
                this.lang != 'en'
                  ? '请联系管理员配置审核模板'
                  : 'Please contact your administrator to configure audit templates',
                'warning',
                false,
                'erp-hey-message-warning'
              )
            } else {
              this.$refs['AuditChoose2'].showAddEdit(auditParams)
            }
          })
        } else {
          this.saveFinally()
        }
      },

      async saveClick2(data) {
        this.form.auditObjectPaymentDTO = data
        this.saveFinally()
      },

      // 保存提交
      async saveFinally() {
        const _address = this.form.companyVO.addressList

        this.form.companyVO.receivingAddressConfigVOs =
          this.form.companyVO.addressList.filter(
            (item) =>
              item.companyName ||
              item.consigneeName ||
              item.consigneePhone ||
              item.nation ||
              item.postcode ||
              item.province ||
              item.nation ||
              item.street ||
              item.unit
          )

        const params = { ...this.form }
        this.loading = true
        const _res = await clientEditInstance.clientUpdatePO(params)
        this.loading = false
        if (_res && _res.code === '000000') {
          this.$message.success(
            this.lang === 'en' ? 'Successfully saved' : '保存成功'
          )
          this.$router.push('/n-customer-manage/cus-center')
        } else {
          this.form.companyVO.addressList = _address
        }
      },

      // 详细收货地址
      getReceivingAddress(row, i) {
        /**
        const _ = (str) => (str ? str : '')
        const address =
          row.street +
          ' ' +
          _(row.unit) +
          ' ' +
          row.city +
          ' ' +
          row.province +
          ' ' +
          row.postcode
         */
        // 如果没有单元号地址，避免拼接2个空格
        let address = ''
        if (row.unit) {
          address = `${row.street} ${row.unit} ${row.city} ${row.province} ${row.postcode}`
        } else {
          address = `${row.street} ${row.city} ${row.province} ${row.postcode}`
        }
        this.form.companyVO.addressList[i].address = address

        return address
      },

      // 设置默认地址
      SetDefaultAddress(i) {
        let addressObj = this.form.companyVO.addressList[i]

        if (addressObj.defaultAddressFlag === 1) {
          addressObj.defaultAddressFlag = 0
        } else {
          addressObj.defaultAddressFlag = 1
        }
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 10px 0;
    min-height: calc(100vh - 200px);
  }

  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
    z-index: 2;
  }

  .add-line {
    color: #409eff;
    text-align: center;
    margin-top: 20px;

    span {
      cursor: pointer;
    }
  }

  .email-err {
    color: #ff4d4f;
    font-size: 12px;
    text-align: left;
    margin-top: -5px;
  }

  ::v-deep {
    .email-wrap {
      position: relative;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;

      .el-form-item {
        padding: 0 10px 0 0 !important;
        width: 100%;
      }

      > i {
        font-size: 24px;
        cursor: pointer;
        margin-top: 5px;
        color: #409eff;
      }
    }

    .el-rate span {
      display: inline !important;
      padding: 0 !important;
    }
  }
</style>
